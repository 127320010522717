<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="12">
          <AddCompany />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import AddCompany from "@/components/AddCompany";
export default {
  name: "AddCompanyPage",
  components: {
    AddCompany,
    PageHeader
  },
   data: () => ({
    headercontent: {
      title: "Add Company",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
  }),
};
</script>

<style></style>
